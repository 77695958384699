import React, { useContext, useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  writeBatch,
  orderBy,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { toast } from "react-toastify";
import { db, storage, functions } from "../firebase";
import { httpsCallable } from "firebase/functions";
const DataContext = React.createContext();
export const useData = () => {
  return useContext(DataContext);
};

const DataProvider = ({ children }) => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [postsData, setPostsData] = useState([]);
  const [categoriesData, setCategoriesData] = useState(null);
  const [websiteContentData, setWebsiteContentData] = useState(null);
  const [logo, setLogo] = useState(null);

  //---------------------Fetching all data at start---------------------------

  useEffect(() => {
    fetchLogo();
    setTableLoading(false);
    fetchUsers();
    fetchPosts();
    fetchCategories();
    fetchWebsiteContent();
  }, []);

  //-------------------------------Logo---------------------------------------
  const fetchLogo = async () => {
    const storageRef = ref(storage, `assets/logo`);
    const downloadURL = await getDownloadURL(storageRef);
    setLogo(downloadURL);
  };

  // --------------------------Users related----------------------------------
  //Fetch users data from firebase
  const formatDate = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000); // Convert Firestore timestamp to JavaScript Date object
    return date.toLocaleDateString(); // Adjust the date format as needed
  };

  const fetchUsers = async () => {
    try {
      //get data orderd by registration time
      const querySnapshot = await getDocs(collection(db, "users" )  , query("registration_time" , "desc"));
      //sort by registration time
      
      const temp = querySnapshot.docs.sort((a, b) => {
        return b.data().registration_time - a.data().registration_time;
      }).map((doc) => {

        const data = doc.data();
        return {
          id: doc.id,
          name: data.name,
          email: data.email,
          registration_time: data.registration_time
            ? formatDate(data.registration_time)
            : "N/A",
        };
      });
      setTableData(temp);
    } catch (e) {
      console.log(e);
    }
  };

  // Fetch user to update user name in the firebase
  const fetchAndUpdateName = async (email, updatedUsername) => {
    setDeleteLoading(true);
    try {
      // Get the reference to the "users" collection
      const usersRef = collection(db, "users");

      // Create a query to find the document with the matching email
      const q = query(usersRef, where("email", "==", email));

      // Execute the query and get the snapshot of matching documents
      const querySnapshot = await getDocs(q);

      // Iterate through the matching documents and update the name
      const updatePromises = querySnapshot.docs.map((docSnapshot) => {
        const userDocRef = doc(usersRef, docSnapshot.id);
        return updateDoc(userDocRef, { name: updatedUsername });
      });

      await Promise.all(updatePromises);
      toast.success("Successfully updated user!", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setDeleteLoading(false);
      fetchUsers();

      console.log("Name updated successfully!");
    } catch (error) {
      toast.error("Error occurred while updating user!", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setDeleteLoading(false);
      console.error("Error updating name:", error);
    }
  };

  // Delete user from firebase
  const deleteUserByEmail = async (email, user) => {
    setDeleteLoading(true);

    // Create callable reference to our Cloud Function
    const deleteUserFunction = httpsCallable(functions, "deleteUser");

    try {
      // Call the function and wait for response
      const result = await deleteUserFunction({ email: email });

      // Check if there is any error
      if (result.data.error) {
        throw new Error(result.data.error);
      }

      toast.success("Successfully deleted user!", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setDeleteLoading(false);
      fetchUsers();
      fetchPosts();
      console.log("User deleted successfully!");
    } catch (error) {
      toast.error("Error occurred while deleting user!", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setDeleteLoading(false);
      console.error("Error deleting user document:", error);
    }

    // })
    // .catch((error) => {
    //   console.log("LOL", currentUser);

    //   console.error("Error deleting user account: ", error);
    // });
  };

  // --------------------------Posts related--------------------------------
  //Fetch posts data from firebase
  const fetchPosts = async () => {
    try {
      const temp = [];
      console.log("fetching posts");
      const postsQuery = query(collection(db, "posts"), orderBy("date_uploaded" , "desc"));
      const querySnapshot = await getDocs(postsQuery);
            querySnapshot.forEach((doc) => {
        console.log(doc.id);
        temp.push({ ...doc.data(), id: doc.id });
      });
      console.log(temp);
      setPostsData(temp);
    } catch (e) {
      console.log(e);
    }
  };

  // Fetch post to update is_pending in the firebase
  const fetchAndUpdatePendingPost = async (id) => {
    setDeleteLoading(true);
    try {
      // Get the reference to the document in the "posts" collection
      const postDocRef = doc(db, "posts", id);

      // Iterate through the matching documents and update the name
      updateDoc(postDocRef, { is_pending: false })
        .then(() => {
          toast.success("Successfully approved post!", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setDeleteLoading(false);
          fetchPosts();
        })
        .catch((err) => {
          toast.error("Error occurred while approving post!", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setDeleteLoading(false);
          fetchPosts();
          console.error("Error: ", err);
        });

      console.log("Post Approved successfully!");
    } catch (error) {
      console.error("Error approving post:", error);
    }
  };

  // Delete post from firebase
  const deletePostById = async (id) => {
    setDeleteLoading(true);
    try {
      // Get the reference to the document in the "posts" collection
      const postDocRef = doc(db, "posts", id);

      // Delete the document
      await deleteDoc(postDocRef)
        .then(() => {
          toast.success("Successfully deleted post!", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setDeleteLoading(false);
        })
        .catch((err) => {
          toast.error("Error occurred while deleting post!", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setDeleteLoading(false);
          console.error("Error: ", err);
        });

      console.log("Post deleted successfully!");
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  //----------------------Website Content related--------------------------
  //Fetch content data from firebase
  const fetchWebsiteContent = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "admin"));
      if (!querySnapshot.empty) {
        const docSnapshot = querySnapshot.docs[0];
        const websiteContent = { id: docSnapshot.id, ...docSnapshot.data() };
        setWebsiteContentData(websiteContent);
      } else {
        console.log("No documents found in the 'admin' collection.");
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  // Fetch and update content in the firebase
  const fetchAndUpdateWebsiteContent = async (updateData, logoData) => {
    setDeleteLoading(true);
    try {
      if (logoData.file) {
        // Upload the logo image to Firebase Storage
        const storageRef = ref(storage, `assets/logo`);
        await uploadBytes(storageRef, logoData.file)
          .then(() => {
            console.log("Uploaded logo successfully.");
          })
          .catch((e) => {
            console.error("Error uploading logo: ", e);
          });

        // Get the download URL of the uploaded image
        const downloadURL = await getDownloadURL(storageRef);
        setLogo(downloadURL);
      }
      // Get the reference to the document in the "admin" collection
      const websiteContentDocRef = doc(db, "admin", updateData.id);

      // Update the document fields with the provided data object
      await updateDoc(websiteContentDocRef, updateData)
        .then(() => {
          toast.success("Successfully updated website content!", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setDeleteLoading(false);
          fetchWebsiteContent();
        })
        .catch((err) => {
          toast.error("Error occurred while updating website content!", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setDeleteLoading(false);
          fetchWebsiteContent();
          console.error("Error fetching web data: ", err);
        });
    } catch (error) {
      console.error("Error updating website content:", error);
    }
  };

  // --------------------------Categories related--------------------------------
  //Fetch categories data from firebase
  const fetchCategories = async () => {
    try {
      const temp = [];
      const querySnapshot = await getDocs(collection(db, "categories"));
      querySnapshot.forEach((doc) => {
        temp.push({ id: doc.id, ...doc.data() });
      });
      setCategoriesData(temp);
    } catch (e) {
      console.log("Error fetching categories: ", e);
    }
  };

  //  Add a new category to the collection
  const addCategory = async (newCategory) => {
    try {
      await addDoc(collection(db, "categories"), newCategory)
        .then(() => {
          toast.success("Successfully added new category!", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setDeleteLoading(false);
          fetchCategories();
        })
        .catch((err) => {
          toast.error("Error occurred while adding category!", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setDeleteLoading(false);
          fetchCategories();
          console.error("Error: ", err);
        });
    } catch (error) {
      console.error("Error adding category: ", error);
    }
  };

  // Fetch and update categories in the firebase
  const fetchAndUpdateCategories = async (updateData) => {
    try {
      // Get the reference to the document in the "categories" collection
      const categoryDocRef = doc(db, "categories", updateData.id);

      // Update the document fields with the provided data object
      await updateDoc(categoryDocRef, updateData)
        .then(() => {
          toast.success("Successfully updated the category!", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setDeleteLoading(false);
          fetchCategories();
        })
        .catch((err) => {
          toast.error("Error occurred while updating category!", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setDeleteLoading(false);
          fetchCategories();
          console.error("Error: ", err);
        });

      // Fetch the updated categories
      fetchCategories();
      console.log("Categories updated successfully!");
    } catch (error) {
      console.error("Error updating categories:", error);
    }
  };

  // Delete category from firebase
  const deleteCategoryById = async (id) => {
    setDeleteLoading(true);
    try {
      // Get the reference to the document in the "categories" collection
      const categoryDocRef = doc(db, "categories", id);

      // Delete the document
      await deleteDoc(categoryDocRef)
        .then(() => {
          toast.success("Successfully deleted the category!", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setDeleteLoading(false);
          fetchCategories();
        })
        .catch((err) => {
          toast.error("Error occurred while deleting category!", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setDeleteLoading(false);
          fetchCategories();
          console.error("Error: ", err);
        });

      fetchCategories();
      console.log("Category deleted successfully!");
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const resetCategoriesForOrder = async (updatedData) => {
    // setDeleteLoading(true);
    try {
      // Delete the existing "categories" collection
      const categoriesCollectionRef = collection(db, "categories");
      const categoriesSnapshot = await getDocs(query(categoriesCollectionRef));
      const categoriesExist = !categoriesSnapshot.empty;

      if (categoriesExist) {
        // If the collection exists, delete all existing documents
        const batch = writeBatch(db);
        categoriesSnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });
        await batch.commit();
      }

      // Create a new collection and add each object as a document
      const batch = writeBatch(db);
      const newCategoriesCollectionRef = collection(db, "categories");
      updatedData.forEach(({ id, ...dataWithoutId }) => {
        const docRef = doc(newCategoriesCollectionRef);
        batch.set(docRef, dataWithoutId);
      });

      await batch.commit().then(() => {
        toast.success("Categories order updated successfully!", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log("Categories updated successfully!");
        fetchCategories();
        setDeleteLoading(false);
      });

      // Fetch the updated categories
    } catch (error) {
      toast.error("Error occurred while updating category!", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      fetchCategories();

      setDeleteLoading(false);
      console.error("Error: ", error);
    }
  };

  const Colors = {
    primaryColor: "#f44336",
    accentColor: "#f44336",
    accentColorLight: "#d2d2d2",
  };

  const logs = [
    { lid: 0, uid: "12", date: Date(), message: "success login to system!" },
    { lid: 1, uid: "12", date: Date(), message: "faliure login to system!" },
    { lid: 2, uid: "12", date: Date(), message: "faliure login to system!" },
    { lid: 3, uid: "12", date: Date(), message: "success login to system!" },
    { lid: 4, uid: "12", date: Date(), message: "success login to system!" },
    { lid: 5, uid: "12", date: Date(), message: "warning login to system!" },
    { lid: 6, uid: "12", date: Date(), message: "warning login to system!" },
    { lid: 7, uid: "12", date: Date(), message: "success login to system!" },
    { lid: 8, uid: "12", date: Date(), message: "fatal login to system!" },
    { lid: 9, uid: "12", date: Date(), message: "fatal login to system!" },
    { lid: 10, uid: "15", date: Date(), message: "success login to system!" },
    { lid: 11, uid: "15", date: Date(), message: "success login to system!" },
    { lid: 12, uid: "15", date: Date(), message: "faliure login to system!" },
    { lid: 13, uid: "15", date: Date(), message: "faliure login to system!" },
    { lid: 14, uid: "15", date: Date(), message: "success login to system!" },
    { lid: 15, uid: "15", date: Date(), message: "success login to system!" },
    { lid: 16, uid: "15", date: Date(), message: "warning login to system!" },
    { lid: 17, uid: "15", date: Date(), message: "warning login to system!" },
  ];

  function siRound(x) {
    if (x < 1e3) return x + "";
    const digits = Math.log10(x) | 0;
    const tier = (digits / 3) | 0;
    let str = (x / 10 ** (tier * 3)).toFixed(2 - (digits % 3));
    // Turn "10.00" into "10.0" and "100.0" into "100"
    str = str.replace(/^(.{3})\..+|^(.{4}).+/, "$1$2");
    if (str.length > 4) {
      ///Add this check
      siRound(str);
    }
    return str + ["", "k", "M", "G", "T"][tier];
  }

  return (
    <DataContext.Provider
      value={{
        logs,
        deleteLoading,
        setDeleteLoading,
        tableData,
        setTableData,
        tableLoading,
        Colors,
        fetchAndUpdateName,
        deleteUserByEmail,
        postsData,
        setPostsData,
        fetchAndUpdatePendingPost,
        deletePostById,
        categoriesData,
        setCategoriesData,
        addCategory,
        fetchAndUpdateCategories,
        deleteCategoryById,
        resetCategoriesForOrder,
        websiteContentData,
        fetchAndUpdateWebsiteContent,
        logo,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
export default DataProvider;
