import classes from "./WebsiteContent.module.css";
import React, { useEffect, useState } from "react";
import { useData } from "../context/DataContext";
import { useScreenSize } from "../context/ScreenSizeDetector";
import RichTextEditor from "./RichTextEditor"; // Import the new component

const WebsiteContent = () => {
  const { deleteLoading, websiteContentData, fetchAndUpdateWebsiteContent, logo } = useData();
  const isMobile = useScreenSize();
  const [tempData, setTempData] = useState(websiteContentData);
  const [bodyContent, setBodyContent] = useState(tempData.bodyContent);
  const [tempLogo, setTempLogo] = useState({
    file: null,
    extension: "",
    imageURL: "",
  });

  //  for data entry
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(event.target);
    if (name === "number" || name === "email" || name === "location") {
      setTempData((prevData) => ({
        ...prevData,
        contact: {
          ...prevData.contact,
          [name]: value,
        },
      }));
    } else {
      setTempData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleTitleChange = (event) => {
    const index = event.target.parentNode.getAttribute("index");
    setBodyContent((prevContent) => {
      if (index >= prevContent.length)
        return [...prevContent, { title: event.target.value, description: "" }];
      else {
        prevContent[index].title = event.target.value;
        return prevContent;
      }
    });
    setTempData((prevData) => ({
      ...prevData,
      bodyContent,
    }));
  };

  const handleDescriptionChange = (event) => {
    const index = event.target.parentNode.getAttribute("index");
    setBodyContent((prevContent) => {
      if (index >= prevContent.length)
        return [...prevContent, { title: "", description: event.target.value }];
      else {
        prevContent[index].description = event.target.value;
        return prevContent;
      }
    });
    setTempData((prevData) => ({
      ...prevData,
      bodyContent,
    }));
  };

  //  for logo image upload
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setTempLogo((prevTempLogo) => ({
      ...prevTempLogo,
      file: file,
    }));

    const reader = new FileReader();

    reader.onloadend = () => {
      setTempLogo((prevTempLogo) => ({
        ...prevTempLogo,
        imageURL: reader.result,
      }));
    };

    reader.readAsDataURL(file);
  };

  //  for form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    fetchAndUpdateWebsiteContent(tempData, tempLogo);
  };

  return (
    <div className={`${classes.main} `} dir="rtl">
      <div className="p-3 container ms-3 me-3">
        <div className={" card me-5 "}>
          <div className={"container card-header "}>
            <h4 className={classes.mainText}>
              <strong>تعديل بيانات الموقع</strong>
            </h4>
          </div>
          <div className={"container card-body "}>
            <form onSubmit={handleSubmit}>
              <div className="form-group mb-2">
                <h5 htmlFor="title">العنوان</h5>
                <input
                  

                  className={!isMobile ? "form-control w-50" : "form-control w-100"}
                  id="title"
                  name="title"
                  value={tempData.title}
                  onChange={handleInputChange}
                />
              </div>
              <hr />
              <div className="form-group mb-2">
                <h5 htmlFor="logo">الشعار</h5>
                <input
                  type="file"
                  className={!isMobile ? "form-control w-50" : "form-control w-100"}
                  id="logo"
                  name="logo"
                  accept="image/.png,.jpg,.jpeg" // Specifying that only image files are allowed
                  onChange={handleImageChange}
                />

                {tempLogo.imageURL && (
                  <div className="d-flex align-items-center mt-3">
                    <h6 className=" me-3" htmlFor="selectedLogo">
                      Selected Logo:
                    </h6>

                    <img
                      className="mt-2"
                      src={tempLogo.imageURL}
                      alt="Logo image"


                      style={{ maxWidth: "150px" ,}}
                    />
                  </div>
                )}
              </div>
              <hr />

              <div className="form-group mb-2">
                <h5 htmlFor="contactUs">تواصل معنا</h5>
                <hr />
                <label htmlFor="contactEmail" className="">
                  البريد الإلكتروني
                </label>
                <input
                  className={!isMobile ? "form-control w-50" : "form-control w-100"}
                  id="contactEmail"
                  name="email"
                  value={tempData.contact.email}
                  onChange={handleInputChange}
                />

                <label htmlFor="contactNumber" className="mt-2">
                  رقم الهاتف
                </label>
                <input
                  className={!isMobile ? "form-control w-50" : "form-control w-100"}
                  id="contactNumber"
                  name="number"
                  value={tempData.contact.number}
                  onChange={handleInputChange}
                />

                <label htmlFor="location" className="mt-2">
                  العنوان
                </label>
                <textarea
                  dir="rtl"
                  className={!isMobile ? "form-control w-50 mb-4" : "form-control w-100 mb-4"}
                  id="location"
                  name="location"
                  value={tempData.contact.location}
                  onChange={handleInputChange}
                  rows={tempData.contact.location ? Math.ceil(tempData.contact.location.length / 70) : 2}
                />
              </div>
              <hr />
              <div className="form-group mb-2">
                <h5 htmlFor="about_us">من نحن</h5>
                <RichTextEditor
                    dir="ltr"
                    className="form-control mb-4"
                    id="about_us"
                    name="about_us"
                    value={tempData.about_us}
                    onChange={handleInputChange}
                    rows={tempData.about_us ? Math.ceil(tempData.about_us.length / 70) : 2}
                />
              </div>
              <hr />
              {/* <div className="form-group mb-2" index="0">
              <h5 htmlFor="head_lines">العناوين</h5>

                <input
                  className="form-control"
                  contenteditable="true"
                
                  value={tempData?.bodyContent?.[0]?.title}
                  onChange={handleTitleChange}
                  placeholder="Title"
                  style={{ marginBottom: "6px", fontSize: "1.25rem",  textAlign: "start"  
                }}
                ></input>
                <textarea
                  dir="rtl"
                  className="form-control mb-4"
                  value={tempData?.bodyContent?.[0]?.description}
                  placeholder="Description"
                  onChange={handleDescriptionChange}
                  rows={
                    tempData?.bodyContent?.[0]?.description
                      ? Math.ceil(tempData.bodyContent[0].title.length / 70)
                      : 2
                  }
                />
              </div>
              <hr />
              <div className="form-group mb-2" index="1">
                <input
                  className="form-control"
                  contenteditable="true"
                  value={tempData?.bodyContent?.[1]?.title}
                  onChange={handleTitleChange}
                  style={{ marginBottom: "6px", fontSize: "1.25rem",  textAlign: "start" }}
                ></input>
                <textarea
                  dir="rtl"
                  className="form-control mb-4"
                  value={tempData?.bodyContent?.[1]?.description}
                  onChange={handleDescriptionChange}
                  rows={
                    tempData?.bodyContent?.[1]?.description
                      ? Math.ceil(tempData.bodyContent[1].description / 70)
                      : 2
                  }
                />
              </div>
              <hr />
              <div className="form-group mb-2" index="2">
                <input
                  className="form-control"
                  contenteditable="true"
                  value={tempData?.bodyContent?.[2]?.title}
                  onChange={handleTitleChange}
                  style={{ marginBottom: "6px", fontSize: "1.25rem",  textAlign: "start" }}
                ></input>
                <textarea
                  dir="rtl"
                  className="form-control mb-4"
                  id="whyUse"
                  name="why_use"
                  value={tempData?.bodyContent?.[2]?.description}
                  onChange={handleDescriptionChange}
                  rows={
                    tempData?.bodyContent?.[2]?.description
                      ? Math.ceil(tempData.bodyContent[2].description / 70)
                      : 2
                  }
                />
              </div>
              <hr />
              <div className="form-group mb-2" index="3">
                <input
                  className="form-control"
                  contenteditable="true"
                  value={tempData?.bodyContent?.[3]?.title}
                  onChange={handleTitleChange}
                  style={{ marginBottom: "6px", fontSize: "1.25rem",  textAlign: "start" }}
                ></input>
                <textarea
                  dir="rtl"
                  className="form-control mb-4"
                  id="marketBusiness"
                  name="market_business"
                  value={tempData?.bodyContent?.[3]?.description}
                  onChange={handleDescriptionChange}
                  rows={
                    tempData?.bodyContent?.[3]?.description
                      ? Math.ceil(tempData.bodyContent[3].description / 70)
                      : 2
                  }
                />
              </div>
              <hr /> */}
              <div className="form-group mb-2">
                <h5 htmlFor="privacyPolicy">سياسة الخصوصية</h5>
                <RichTextEditor ditor
                  dir="rtl"
                  className="form-control mb-4"
                  id="privacyPolicy"
                  name="privacy_policy"
                  value={tempData.privacy_policy}
                  onChange={handleInputChange}
                  rows={tempData.privacy_policy ? Math.ceil(tempData.privacy_policy.length / 70) : 2}
                />
              </div>
              <hr />
              <div className="form-group mb-2">
                <h5 htmlFor="eula">اتفاقية المستخدم النهائي (EULA)</h5>
                <RichTextEditor
                  dir="rtl"
                  className="form-control mb-4"
                  id="eula"
                  name="eula"
                  value={tempData.eula}
                  onChange={handleInputChange}
                  rows={tempData.eula ? Math.ceil(tempData.eula.length / 130) : 2}
                />
              </div>
              <hr />
              <div className="form-group mb-2">
                <h5 htmlFor="termsConditions">الشروط والأحكام</h5>
                <RichTextEditor
                  dir="rtl"
                  className="form-control mb-4"
                  id="termsConditions"
                  name="terms_and_conditions"
                  value={tempData.terms_and_conditions}
                  onChange={handleInputChange}
                  rows={
                    tempData.terms_and_conditions ? Math.ceil(tempData.terms_and_conditions.length / 70) : 2
                  }
                />
              </div>
              <hr />
              <div className="form-group mb-2">
                <h5 htmlFor="faq">الاسئلة الشائعة</h5>
                <RichTextEditor
                
                  // dir="rtl"
                  // className="form-control mb-4"
                  id="faq"
                  name="faq"
                  value={tempData.faq}
                  onChange={handleInputChange}
                  // rows={tempData.faq ? Math.ceil(tempData.faq.length / 70) : 2}
                />
              </div>
              <hr />
              <div className="form-group mb-2">
                <h5 htmlFor="ctaContent">العنوان السفلي</h5>
                <RichTextEditor
                  dir="rtl"
                  className="form-control mb-4"
                  id="ctaContent"س
                  name="cta_content"
                  value={tempData.cta_content}
                  onChange={handleInputChange}
                  rows={tempData.cta_content ? Math.ceil(tempData.cta_content.length / 40) : 2}
                />
              </div>
             
              <button type="submit" disabled={deleteLoading} className="btn btn-primary">
                تحديث
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebsiteContent;
