import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const RichTextEditor = ({ value, name, onChange }) => {
  const handleChange = (content) => {
    onChange({ target: { name, value: content } });
  };
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }], // Header options
      ["bold", "italic", "underline"], // Formatting buttons
      [{ direction: "rtl" }], // Right-to-Left button
      [{ align: [] }], // Alignment options
      ["link", ], // Media options
      ["clean"], // Remove formatting
    ],
  };
  return (
    <ReactQuill
      dir="rtl"

      value={value}
      onChange={handleChange}
      theme="snow"
      modules={modules}

      className="mb-4"
      style={{ minHeight: "150px" }}
    />
  );
};

export default RichTextEditor;
